@font-face {
    font-family: Tahoma;
    src: url(../public/assets/font/Tahoma-Regular-font.ttf);
}

@font-face {
    font-family: Roboto;
    src: url(../public/assets/font/Roboto-Regular.ttf);
}

.cst-font-tahoma {
    font-family: Tahoma;
}

.seller-dashaboard-class {
    background: linear-gradient(180deg, #440F3B 0%, #3A1740 28.71%, #2E143D 59.94%, #04041A 100%);
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

.cst-border-white {
    background: none !important;
    border-color: white;
    color: white;
}

.add-product-cst {
    cursor: pointer;
}

.from-icon-cst {
    color: #2CB196;
    font-size: 16px;
    margin-right: 8px;
}

.member-since-icon-cst {
    color: #2CB196;
    font-size: 14px;
    margin-right: 8px;
}

.clas-cat {
    background: transparent !important;
    color: white !important;
}

select option {
    background: #3A1740 !important;
    color: white !important;
}

.form-select {
    border-radius: 10px !important;
}

.Select {
    background: #3A1740 !important;
    color: white !important;
}

.cst-bk {
    background: linear-gradient(180deg, #440F3B 0%, #3A1740 28.71%, #2E143D 59.94%, #04041A 100%);
    color: white;
}

.css-1p3m7a8-multiValue {
    background-color: hsl(295.71deg 50% 16.47%) !important;
    border-radius: 7px !important;
    margin: 2px;
    padding: 2px;
}

.css-wsp0cs-MultiValueGeneric {
    color: white !important;
}

.css-12a83d4-MultiValueRemove:hover {
    background-color: #3c153f !important;
    color: white !important;
}

.btn-new-key-genrate {
    color: white !important;
    border: 1px solid white;
    border-radius: 6px;
    background-color: #522054;
}

.btn-green-cst {
    background-color: #156353 !important;
    color: white !important;
    font-size: 16px;
}

.btn-green-cst:hover {
    background-color: #156353 !important;
    color: white !important;
}

.modal-header {
    border-bottom: none;
}

.btn-facebook-btn {
    color: white !important;
    border: 1px solid white;
    border-radius: 6px;
    background-color: #3d153f;
    font-size: 16px;
    font-family: Roboto;
    font-style: normal;
    /* font-weight: 500; */
    font-weight: 600;
}

.btn-apple-btn {
    color: black !important;
    border: 1px solid white;
    border-radius: 6px;
    font-size: 16px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
}

.forgot-password-a {
    font-size: 16px !important;
    color: #2CB196 !important;
    float: right;
    font-family: Tahoma;
}

.join-now-a {
    font-size: 16px !important;
    color: #2CB196 !important;
    font-family: Tahoma;
}

.n-remember-yet {
    font-size: 16px !important;
    font-family: Tahoma;
}

span {
    font-size: 16px !important;
    font-family: Tahoma;
}

.main_logo {
    cursor: pointer;
}

.close-icons {
    font-size: 25px;
    cursor: pointer;
}

.cst-logout-cls {
    cursor: pointer;
    font-size: 20px;
}

.join-moonetized-model {
    font-family: 'Tahoma';
    font-size: 16px
}


::-webkit-input-placeholder {
    /* WebKit browsers */
    font-family: Roboto;
    font-style: normal;
    line-height: 24px;
    font-weight: 400;
    color: #FFFFFF !important;
}

:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    font-family: Roboto;
    font-style: normal;
    line-height: 24px;
    font-weight: 400;
    color: #FFFFFF !important;
}

::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    font-family: Roboto;
    font-style: normal;
    line-height: 24px;
    font-weight: 400;
    color: #FFFFFF !important;
}

:-ms-input-placeholder {
    /* Internet Explorer 10+ */
    font-family: Roboto;
    font-style: normal;
    line-height: 24px;
    font-weight: 400;
    color: #FFFFFF !important;
}

.input-container {
    position: relative;
}

.icon-container {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 20px;
    cursor: pointer;
}

.input-container input::placeholder {
    text-align: left;
}

.multislect-cst {
    font-family: 'Roboto';
}

.clas-cat {
    font-family: 'Roboto';
    font-size: 16px;
}

.search-for-anything {
    background-color: #2C133B;
    color: #FFFFFF;
    border-color: #B186D2;
    height: 50px;
    font-family: Roboto;
    font-size: 16px;
    padding: 0px 0px 0px 18px;
    border-radius: 20px;
    margin-right: 100px;
    width: 100%;
}

ul.d-flex {
    display: flex;
    justify-content: center;
    align-items: center;
    list-style: none;
    margin: 0;
    padding: 0;
}

ul.d-flex li {
    margin-right: 20px;
}

ul.d-flex li:last-child {
    margin-right: 0;
}

.preferred-c {
    display: inline-flex;
    width: 101% !important;
}

@media (max-width: 767px) {
    .nav_inner {
        flex-wrap: wrap;
    }

    .navbar-expand-lg .navbar-nav {
        display: none;
    }

    .navbar-toggler {
        display: block;
    }

    .main_logo {
        margin: 0 auto;
        display: block;
    }

    ul.d-flex {
        flex-wrap: wrap;
        justify-content: center;
        margin: 20px 0 10px 0;
    }

    ul.d-flex li {
        margin-right: 0;
        margin-bottom: 10px;
    }

    ul.d-flex li:last-child {
        margin-right: 0;
    }

    .text-white.preferred-c {
        margin-right: 0;
        margin-bottom: 10px;
    }
}


.round-images-csr {
    border-radius: 50%;
    width: 150px;
}

@media (max-width: 575.98px) {
    .d-flex.justify-content-between {
        flex-wrap: wrap;
        /* Allow flex items to wrap onto multiple lines */
        justify-content: center;
        /* Center the flex items horizontally */
    }

    .d-flex.flex-column.align-items-center {
        margin: 10px 20px;
        /* Add some margin to the flex items */
        width: calc(50% - 40px);
        /* Set the width of each flex item to half of the container minus some margin */
        max-width: 150px;
        /* Set a maximum width for the flex items */
        text-align: center;
        /* Center the text horizontally */
    }
}




.thumbs-wrapper {
    display: flex;
    gap: 25px;
    flex-direction: column;
}

.thumbs-wrapper>div {
    position: relative;
    max-width: 92px;
    cursor: pointer;
    overflow: hidden;
    user-select: none;
    border-radius: 10px;
    border: 2px solid transparent;
    transition: 0.2s linear;
}

.thumbs-wrapper>div.active {
    border-color: #ff7d1a;
}

.thumbs-wrapper>div:hover::after,
.thumbs-wrapper>div.active::after {
    opacity: 1;
}

.thumbs-wrapper>div::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    background: rgba(255, 255, 255, 0.5);
    transition: 0.2s linear;
}

.thumbs-wrapper>div img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.product-details-wrapper {
    padding: 24px;
    font-weight: 700;
}


/*
  All of the CSS below is strictly for the demo.  none of the components rely on this for actual
  functionality.
*/



.responsiveImg {
    max-width: 100%;
}

.secondSlide {
    max-width: 768px;
    margin: auto;
    padding: 20px;
    font-size: 1.2rem;
}


.headline {
    text-align: center;
}

.select {
    width: 300px;
}

.slider {
    max-width: 800px;
}

.externalLink {
    vertical-align: baseline;
    margin: 0 0.4rem;
    width: 1.4rem;
    height: 1.4rem;
    position: relative;
    top: 0.2rem;
}

.verticalSlider {
    width: 100%;
    max-width: 300px;
}

.express {
    text-align: center;
    padding: 2rem;
}


.form-select {
    background-color: #522054;
    color: white;
}

.select-arrow {
    background-color: #34163f;
    color: white;
}

.cst-cursor-pointer {
    cursor: pointer;
}

.dropdown-item:focus,
.dropdown-item:hover {
    background-color: #B186D2;
    color: white;
}

.dropdown-item {
    color: white;
}

.dropdown-menu.show {
    background-color: #522054;
}

.online-button-seller-dashbord {
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.1px;
    /* font-size: 14px; */
    font-family: roboto;
    padding: 4px 10px 4px 4px;
    border-color: white;
    border-radius: 18px;
}

.green-dot {
    color: #2CB196;
}